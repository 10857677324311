@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};   
    @media (prefers-reduced-motion) {
      .animated {
        -webkit-animation: unset !important;
        animation: unset !important;
        -webkit-transition: none !important;
        transition: none !important;
      }
    }   
  }
  
  @mixin keyframes($animation-name) {
      @-webkit-keyframes #{$animation-name} {
          @content;
      }
      @-moz-keyframes #{$animation-name} {
          @content;
      }  
      @-ms-keyframes #{$animation-name} {
          @content;
      }
      @-o-keyframes #{$animation-name} {
          @content;
      }  
      @keyframes #{$animation-name} {
          @content;
      }
  }
  
  @mixin transform($value){
      -webkit-transform: $value;
      -moz-transform: $value;
      -ms-transform: $value;
      -o-transform: $value;
      transform: $value;
  }
  
 
  
  .doing{
    transform: rotate(-35deg);
    display: block;
    position: absolute;
    top: 0;
    left: -90px;
  }
  
  .will-fadeIn{
    width: 100%;
  }
  
  .fadeInRight {
      @include animation('fadeInRight .5s ease .4s both');
  
  }
  
  .fadeInLeft {
      @include animation('fadeInLeft .5s ease .4s both');
  }
  
  @include keyframes(fadeInRight) {
      from {
          opacity: 0;
          @include transform( translate3d(100%, 0, 0));
      }
  
      to {
          opacity: 1;
          @include transform( translate3d(0, 0, 0));
      }
  }
  
  @include keyframes(fadeInLeft) {
      from {
          opacity: 0;
          @include transform( translate3d(-100%, 0, 0));
      }
  
      to {
          opacity: 1;
          @include transform( translate3d(0, 0, 0));
      }
  }